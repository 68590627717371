// in nuxt config auth redirect is set to false for the login modals
// apply middleware: 'authRedirect', to the page if you want the page to redirect to /login if not authenticated
export default function ({ store, redirect, $auth }) {
  // Redirect to login if the user is not authenticated
  if (!store.state.auth.loggedIn) {
    return redirect('/login');
  }
  if ($auth.strategy.token.status().expired()) {
    // Log out and redirect to login
    $auth.logout();
    return redirect('/login');
  }
}
